import logo from './images/lastdie-skull.png';
import ss1 from './images/latdieS1.png';
import ss1Mobile from './images/latdieS1-mobile.png';
import diceline from './images/diceline.png';
import dicelineMobile from './images/diceline-mobile.png';
import appstoreapple from './images/appstoreapple.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="container">
                <div className="flex-container">
                    <div className="wrapper">
                        <div className="row">
                            <div className="flex-item text-center">
                                <img src={logo} className="mainlogo" alt="Last Die Logo"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className='finalDetails-mobile ' style={{
                                    background: 'rgba(108, 122, 137, .2)',
                                    padding: '30px',
                                    borderRadius: '15px',
                                    position: 'relative',
                                }}>
                                    <div className=" logo-item">
                                        <img src={ss1Mobile} className="logo-half" alt="Last Die Logo"/>
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        top: '30px'
                                    }} >
                                        <h1>
                                            LAST DIE
                                        </h1>
                                        <p style={{paddingBottom: '30px', paddingTop: '30px'}}>
                                            Answer the call of the dice! Roll until you can roll no more, the player with the highest score wins! Customize your playing board and dice. Download for free and roll your way to victory!
                                        </p>
                                        <img src={appstoreapple} className="buylogo" alt="Last Die App Store Link" onClick={() => {
                                            window.open('https://apps.apple.com/us/app/last-die/id1580841101');
                                        }}/>
                                    </div>
                                </div>
                                <div className="flex-container1 finalDetails" style={{
                                    background: 'rgba(108, 122, 137, .2)',
                                    padding: '30px',
                                    borderRadius: '15px',
                                    position: 'relative',
                                }}>
                                    <div className="flex-item1 logo-item">
                                        <img src={ss1} className="logo" alt="Last Die Logo"/>
                                    </div>
                                    <div className="flex-item2" >
                                        <h1>
                                            LAST DIE
                                        </h1>
                                        <p style={{paddingBottom: '30px', paddingTop: '30px'}}>
                                            Answer the call of the dice! Roll until you can roll no more, the player with the highest score wins! Customize your playing board and dice. Download for free and roll your way to victory!
                                        </p>
                                        <img src={appstoreapple} className="buylogo" alt="Last Die App Store Link" onClick={() => {
                                            window.open('https://apps.apple.com/us/app/last-die/id1580841101');
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className="flex-container1 no-flex-mobile" style={{
                                    background: 'rgba(108, 122, 137, .2)',
                                    padding: '30px',
                                    borderRadius: '15px'
                                }}>
                                    <div className="flex-item1-video">
                                        <h2>
                                            ROLL YOUR WAY TO VICTORY!
                                        </h2>
                                        <p style={{paddingTop: '30px'}}>
                                            A fun causal dice game. Get 6 dice per match, a D4, D6, D8, D10, D12, and D20. Roll to see who gets the highest outcome. Avoid rolling the Last Die King or your opponent will get a bonus modifier. Roll the highest number and keep the die to roll again!
                                        </p>
                                    </div>
                                    <div className="flex-item2-video pt-10-m">
                                        <div className='video-width'>
                                            <iframe width="100%" height="315"
                                                    src="https://www.youtube.com/embed/uEOrnFPmPwY"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className="flex-container1 kill-gap" style={{
                                    background: 'rgba(108, 122, 137, .2)',
                                    padding: '30px',
                                    borderRadius: '15px',
                                    position: "relative"
                                }}>
                                    <div className='finalDetails-mobile'>
                                        <div style={{textAlign: 'center'}}>
                                            <img src={dicelineMobile} className="diceline-mobile" alt="Last Die Logo"/>
                                        </div>
                                        <h3>
                                            MORE DICE, LEADERBOARD, AND ACHIEVEMENTS!
                                        </h3>
                                        <p style={{paddingTop: '30px'}}>
                                            Choose between different styles of dice and game boards. Unlock achievements when you win matches. Roll your way to #1 on the leaderboard. To stay on top of your game, keep an eye on realityse.com and sneak peeks on what the team is working on.
                                        </p>
                                    </div>
                                    <div className='finalDetails' style={{position: "absolute",  left: '35%', paddingRight: '30px', paddingBottom: '30px'}}>
                                        <h3>
                                            MORE DICE, LEADERBOARD, AND ACHIEVEMENTS!
                                        </h3>
                                        <p style={{paddingTop: '30px'}}>
                                            Choose between different styles of dice and game boards. Unlock achievements when you win matches. Roll your way to #1 on the leaderboard. To stay on top of your game, keep an eye on realityse.com and sneak peeks on what the team is working on.
                                        </p>
                                    </div>
                                    <div>
                                        <img src={diceline} className="diceline" alt="Last Die Logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-container-footer text-center">
                    <div className="wrapper">
                        <div className="footer">
                            © 2022-{new Date().getFullYear()} Reality Software Entertainment, Inc. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
